import axiosLib from "axios";
import { DropdownMenuItemType, IconButton, TextField } from 'office-ui-fabric-react';
import React, { PureComponent } from 'react';
import intl from 'react-intl-universal';
import axios from '../../axios-wagx-web';
import { getLocalizedProperty } from '../../LocaleUtils';
import { computeDependsOnFieldsUrl, isNullOrEmpty, parseString } from '../../functions/utility'
import ComboBoxWithRenderLabel from "../ComboBoxWithRenderLabel/ComboBoxWithRenderLabel";
import { flattenDeep } from 'lodash';
import './SelectField.css';

const selectFieldHeaderKey = "SelectFieldHeader";

class SelectField extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      itemsLoaded: false,
      isInError: false,
      filter: ""
    }

    this.filterHandler = null;
    this.filterInputHandler = null;
    this.textInput = null;
    this.filterNoResult = false;
    this.refreshInterval = null;
    this.persistFilter = this.props.persistFilter != null ? this.props.persistFilter : false;
    this.selectedItems = [];
  }

  componentDidMount() {
    if (!this.state.itemsLoaded) {
      this.loadData();
    }
    if (this.filterHandler != null) {
      clearTimeout(this.filterHandler);
      this.filterHandler = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.itemsLoaded && prevProps.value !== this.props.value) {
      let updateValue = false;
      let actualValue = this.state.items.filter(item => item.selected).map(item => item.key).sort();
      let prevValue = [];
      if (Array.isArray(this.props.value)) {
        prevValue = [...this.props.value];
      } else if (this.props.value !== "") {
        prevValue = [this.props.value];
      }
      if (actualValue.length !== prevValue.length) {
        updateValue = true;
      } else {
        actualValue = actualValue.sort();
        prevValue = prevValue.sort();
        for (let i = 0; i < actualValue.length; i++) {
          if (actualValue[i] !== prevValue[i]) {
            updateValue = true;
            break;
          }
        }
      }

      if (updateValue) {
        const items = this.state.items.map((item) => {
          let itm = Object.assign({}, item);
          itm.selected = false;
          return itm;
        });
        let value = this.props.value;
        if (!Array.isArray(value)) {
          if (isNullOrEmpty(value)) {
            value = [];
          } else {
            value = [value];
          }
        }
        this.selectedItems = this.selectedItems.filter((item) => {
          let isSelected = false;

          if (Array.isArray(item)) {
            isSelected = item.every(val => value.includes(val));
          } else {
            isSelected = value.includes(item);
          }

          return isSelected;
        });
        let found = false;
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (this.selectedItems.findIndex(selecteditem => selecteditem === item.key) > -1) {
            found = true;
            item.selected = true;
          }
        }
        if (found || value.length === 0) {
          this.setState({ items: items });
        }
      }
    }

    let shouldReloadItems = false;
    if (prevProps.dependsOnFields && this.props.dependsOnFields.length) {
      if (prevProps.dependsOnFields.length !== this.props.dependsOnFields.length) {
        shouldReloadItems = true;
      }

      if (shouldReloadItems === false) {
        //verifico che i value dei dependsOnField siano Array e se sono variati di dimensioni
        if (prevProps.dependsOnFields.length === this.props.dependsOnFields.length) {
          this.props.dependsOnFields.forEach((item) => {
            if (Array.isArray(item.value)) {
              //ricavo l'index dal param perché non so come sono ordinati tra i 2
              let prevIndex = prevProps.dependsOnFields.findIndex(prevItem => prevItem.param === item.param);
              if (prevIndex > -1) {
                if (item.value.length !== prevProps.dependsOnFields[prevIndex].value.length) {
                  shouldReloadItems = true;
                }
              }
            }
          })
        }

        if (shouldReloadItems === false) {
          this.props.dependsOnFields.forEach((dependsOnField, index) => {
            const prevDependsOnField = prevProps.dependsOnFields[index];
            if (dependsOnField.value !== prevDependsOnField.value) {
              shouldReloadItems = true;
            }
          });
        }
      }
    }
    if (shouldReloadItems) {
      this.loadData();
    }
  }

  _setValue = (items) => {
    let value = this.props.value;
    if (!Array.isArray(value)) {
      if (value === "" || value === null) {
        value = [];
      } else {
        value = [value];
      }
    }
    let isSelected = false;
    for (let i = 0; i < value.length; i++) {
      const val = value[i];
      const idx = items.findIndex((item) => {
        return item.id === val || item.key === val
      });
      if (idx >= 0) {
        items[idx].selected = true;
        isSelected = true;
      }
    }
    if (this.props.defaultSelectedFirst === true && isSelected === false) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].key !== selectFieldHeaderKey && items[i].key != null) {
          items[i].selected = true;
          break;
        }
      }
    }
    return items;
  }

  fireOnChange = (newVal) => {
    this.selectedItems = Array.isArray(newVal) ? [...newVal] : [];
    if (!Array.isArray(newVal)) {
      this.selectedItems.push(newVal)
    }

    let val = newVal;
    if (newVal != null) {
      if (Array.isArray(newVal) === true) {
        val = newVal.map(v => v.hasOwnProperty("key") ? v.key : v);
        val = flattenDeep(val);
      } else if (newVal.hasOwnProperty("key")) {
        val = val.key;
      }
    }
    if (Array.isArray(this.props.manageExtraFields)) {
      const extraFieldsField = [];
      const extraFieldsValue = [];
      extraFieldsField.push(this.props.name);
      extraFieldsValue.push(val);
      this.props.manageExtraFields.forEach(extraField => {
        const param = typeof extraField === "string" ? extraField : extraField.param;
        const field = typeof extraField === "string" ? extraField : extraField.field;

        let v = null;
        if (newVal != null && newVal.extraFields != null && newVal.extraFields[param] != null) {
          v = newVal.extraFields[param];
        }

        extraFieldsField.push(field);
        extraFieldsValue.push(v);
      });

      if (this.props.useMultiFieldOnChange === true) {
        this.props.onChange(null, extraFieldsField, extraFieldsValue, null);
      } else {
        for (let i = 0; i < extraFieldsField.length; i++) {
          this.props.onChange(null, extraFieldsField[i], extraFieldsValue[i], null);
        }
      }
    } else {
      // Gestione vecchia
      this.props.onChange(null, this.props.name, val, null);
    }
  }

  loadData = () => {
    const doLoadData = () => {
      if (this.requestCancelToken == null) {
        this.requestCancelToken = axiosLib.CancelToken.source();
      }

      let items = [];
      const placeholder = this.props.placeholder
        ? getLocalizedProperty(this.props, "placeholder")
        : intl.get("SelectField.selectAnItem").d("--Selezionare un Elemento--");
      const header = { id: selectFieldHeaderKey, key: selectFieldHeaderKey, text: placeholder, itemType: DropdownMenuItemType.Header };
      const noSelection = { id: "SelectFieldNoSelection", key: null, text: intl.get("SelectField.noSelection").d("--Nessun Selezionato--") };

      //Prevalorizzo items
      items.push(header);
      if (!this.props.multiSelect && !this.props.required) {
        items.push(noSelection);
      }

      let url = "lovs/" + this.props.lovId + "?";
      if (this.props.lovId) {
        let isFilterPresent = false;
        if (this.props.applyFilters) {
          //filtri che arrivano dai link
          this.props.applyFilters.forEach(element => {
            let param = element.param;
            if (this.props.remapAppliedFiltersFields != null) {
              const remappedFilter = this.props.remapAppliedFiltersFields.find((remap) => remap.from === param);
              if (remappedFilter) {
                url += "&" + remappedFilter.to + "=" + element.value;
                isFilterPresent = true;
              }
            }
          });
        }

        url = this.computeExtraFieldsUrl(url);

        const computedDependsOnFieldsObj = computeDependsOnFieldsUrl(url, this.props.dependsOnFields);
        url = computedDependsOnFieldsObj.url;
        isFilterPresent = isFilterPresent || computedDependsOnFieldsObj.isFilterPresent;

        if (isFilterPresent || this.props.callOnlyWhenFilterIsPresent === false || this.props.callOnlyWhenFilterIsPresent == null) {
          if (this.props.onSelectFieldBeforeLoadData) {
            this.props.onSelectFieldBeforeLoadData(this.props.name);
          }
          axios.get(url, { cancelToken: this.requestCancelToken.token })
            .then(response => {
              this.requestCancelToken = null;
              if (response.data.success) {
                const responseList = [...response.data.value];

                if (this.props.lovValue != null) {
                  const lovValueElement = [...this.props.lovValue];
                  lovValueElement.forEach(element => {
                    const curObject = { id: element.value, value: element.value, description: getLocalizedProperty(element, "description"), extraFields: element.extraFields };
                    responseList.push(curObject);
                  });
                }

                const valueExists = this.props.value != null && this.props.value !== "";
                const valueExistsInList = responseList.some(element => element.value === this.props.value );

                responseList.forEach(element => {
                  let text = element.description ? element.description : "";

                  if (this.props.translationKey != null) {
                    const parsedKey = parseString(this.props.translationKey, element);
                    text = intl.get(parsedKey).d(text);
                  }

                  const curObject = {
                    id: element.id,
                    key: element.value,
                    text: text,
                    disabled: false,
                    extraFields: element.extraFields
                  };

                  if (!valueExists || !valueExistsInList) {
                    curObject.selected = element.selected;
                  } else if (valueExistsInList) {
                      curObject.selected = element.value === this.props.value;
                  }
                  
                  items.push(curObject);
                });

                items = this._setValue(items);
                if (this.props.invokeOnChangeOnLoad === undefined || this.props.invokeOnChangeOnLoad === true) {
                  let newVal = items.filter(item => item.selected);//.map(item => item.key);
                  if (Array.isArray(newVal) && newVal.length === 1) {
                    newVal = newVal[0];
                  } else if (newVal.length === 0) {
                    newVal = this.props.multiSelect ? [] : "";
                  }
                  this.fireOnChange(newVal);
                }

                this.setState({ items: items, itemsLoaded: true, isInError: false }, () => {
                  if (this.props.handleLovIdFilters) {
                    this.props.handleLovIdFilters(items, this.props.name);
                  }

                  if (this.props.onSelectFieldLoadData) {
                    this.props.onSelectFieldLoadData(this.props.name, responseList);
                  }
                });
              } else {
                this.setState({ itemsLoaded: true, isInError: false });
              }
              this.requestCancelToken = null;
            })
            .catch(exception => {
              if (!axiosLib.isCancel(exception)) {
                console.error("SelectField with lovId " + this.props.lovId + " failed to load items", exception)
                if (this.props.onExceptionHandler) {
                  this.props.onExceptionHandler();
                }
                this.setState({ isInError: true });
              }
            });
        }
      } else if (this.props.lovValue) {
        const lovValueElement = [...this.props.lovValue];
        const valueExists = this.props.value !== "" && this.props.value != null;
        lovValueElement.forEach(element => {
          const curObject = { id: element.value, key: element.value, text: getLocalizedProperty(element, "description"), extraFields: element.extraFields };
          if (!valueExists) {
            curObject.selected = element.selected;
          }
          items.push(curObject);
          if (this.props.onSelectFieldLoadData) {
            this.props.onSelectFieldLoadData(this.props.name, [...items]);
          }
        });
        items = this._setValue(items);
        if (this.props.invokeOnChangeOnLoad !== false) {
          let newVal = items.filter(item => item.selected).map(item => item.key);
          if (Array.isArray(newVal) && newVal.length === 1) {
            newVal = newVal[0];
          } else if (newVal.length === 0) {
            newVal = null;
          }
          this.fireOnChange(newVal);
          //          this.props.onChange({ target: { value: newVal } }, this.props.name, newVal, null);
        }
        this.setState({ items: items, itemsLoaded: true });
      }
    }

    const executeLoadData = () => {
      //se ho i dati già caricati ma sto effettuando un ricarico a causa di un didUpdate allora devo dare evidenza all'utente
      if (this.state.itemsLoaded) {
        this.setState({ itemsLoaded: false }, () => {
          doLoadData();
        });
      } else {
        doLoadData();
      }
    }

    if (this.refreshInterval === null && this.props.refreshInterval && !isNaN(this.props.refreshInterval)) {
      executeLoadData();
      this.refreshInterval = setInterval(executeLoadData, this.props.refreshInterval);
    } else {
      executeLoadData();
    }
  }

  computeExtraFieldsUrl = (url) => {
    if (Array.isArray(this.props.manageExtraFields)) {
      this.props.manageExtraFields.forEach(extraField => {
        const param = typeof extraField === "string" ? extraField : extraField.param;

        url += "&extraFields=" + param;
      });
    }

    return url;
  }

  componentWillUnmount() {
    this.onCloseHandler();
    if (this.requestCancelToken != null) {
      this.requestCancelToken.cancel();
      this.requestCancelToken = null;
    }
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
    if (this.focusTimeout != null) {
      clearTimeout(this.focusTimeout);
    }
  }

  onChangeHandler = (event, option, index, value) => {
    const items = this.state.items.map((item) => Object.assign({}, item));
    items.forEach((item) => { if (item.key === option.key) item.selected = option.selected });
    this.setState({ items: items });

    if (!this.props.notifyOnClose) {
      if (this.props.multiSelect) {
        const items2 = items.filter(item => item.selected).map(item => item.key);
        this.fireOnChange(items2);
        //        this.props.onChange(event, this.props.name, items2, null);
      } else {
        this.fireOnChange(option);
        //        this.props.onChange(event, this.props.name, option.key, null);
      }
    }
  }

  _setFocusOnSearchInput = () => {
    this.focusTimeout = setTimeout(() => {
      if (this.textInput != null) {
        this.textInput.focus(); // Set by timeout to force focus on filter when the filter execute.
        this.focusTimeout = null;
      } else {
        this._setFocusOnSearchInput();
      }
    }, 50);
  }

  onMenuOpen = () => {
    if (this.props.useLiveSearch == null || this.props.useLiveSearch === true) {
      this._setFocusOnSearchInput();
    }
  }

  onMenuDismissed = () => {
    if (this.focusTimeout != null) {
      clearTimeout(this.focusTimeout);
    }
    if (this.props.onMenuDismissedHandler != null) {
      const items = this.state.items.filter((item) => item.selected === true);
      const values = items.map((item) => item.key)
      this.props.onMenuDismissedHandler(null, this.props.name, values);
    }

    if (this.persistFilter === false) {
      this.setState({ filter: "" });
    }
  }

  onCloseHandler = () => {
    if (this.props.notifyOnClose) {
      const items = this.state.items.map((item) => Object.assign({}, item));
      const items2 = items.filter(item => item.selected).map(item => item.key);
      this.fireOnChange(items2);
    }
  }

  _onRenderOptionHandler = (option, event) => {
    if (option.id === 'SelectFieldHeader') {
      if (this.props.useLiveSearch == null || this.props.useLiveSearch === true) {
        this._setFocusOnSearchInput();
      }
      return (
        <div className={"SelectFieldHeaderContentContainer" + (this.filterNoResult ? " noResult" : '')}>
          {this.props.multiSelect ?
            <IconButton
              iconProps={{ iconName: 'MultiSelect', color: "black" }}
              onClick={this.selectDeselectAll}
              title={intl.get("SelectField.IconButton.title").d("Seleziona/Deseleziona tutto")}
              className="multiSelectSelectionAll"
            />
            : null}
          <TextField
            ref='filterRef'
            componentRef={(input) => this.textInput = input}
            onChange={this._filterChangeHandler}
            defaultValue={this.state.filter}
            iconProps={{ iconName: 'Filter' }}
            borderless={false}
            className={"textfield-border-bottom-only" + (this.props.multiSelect ? " multiSelectSearch" : "")}
          />
        </div>
      );
    } else {
      return (<span value={option.id} key={option.key} className={option.className}>{option.text}</span>);
    }
  }

  _filterChangeHandler = (event, text) => {
    if (this.filterHandler != null) {
      clearTimeout(this.filterHandler);
      this.filterHandler = null;
    }
    this.filterHandler = setTimeout(
      () => {
        this.setState({ filter: text });
      }, 500
    );
  }

  selectDeselectAll = () => {
    let allSelected = true;
    const items = this.state.items.map((item) => Object.assign({}, item));
    let selectedItems = items.filter(item => item.selected);

    const visibleItems = this.filterItems(items);

    visibleItems.forEach(item => {
      if (item.itemType !== DropdownMenuItemType.Header) {
        if (selectedItems.findIndex((selectedItem) => selectedItem.key === item.key) === -1) {
          allSelected = false;
          return false;
        }
      }
    });

    if (allSelected) {
      items.forEach(item => {
        if (visibleItems.findIndex(visibleItem => visibleItem.key === item.key) !== -1) {
          item.selected = false;
        }
      })
    } else {
      items.forEach(item => {
        if (visibleItems.findIndex(visibleItem => visibleItem.key === item.key) !== -1) {
          if (item.itemType !== DropdownMenuItemType.Header) {
            item.selected = true;
          }
        }
      })
    }

    if (!this.props.notifyOnClose) {
      const items2 = items.filter(item => item.selected).map(item => item.key);
      this.fireOnChange(items2);
    }

    this.setState({ items: items });
  }

  filterItems = (items) => {

    let filterNoResult = false;
    let filteredItems = [];
    const options = items.map((item) => {
      return {
        key: item.key,
        text: item.text,
        id: item.id,
        extraFields: item.extraFields,
        itemType: item.itemType,
        disabled: item.disabled
      }
    });
    const noSelection = { id: "SelectFieldNoResult", key: "SelectFieldNoResult", text: intl.get("SelectField.noResult").d("--Nessun Risultato--"), className: "DisabledOption", itemType: DropdownMenuItemType.Header };

    if (!this.state.filter || this.state.filter === "") {
      if (options.length === 1) {
        options.push(noSelection);
      }
      filteredItems = options;
    } else {
      options.forEach(item => {
        if (item.id === 'SelectFieldHeader' || item.id === 'SelectFieldNoSelection') {
          filteredItems.push(item);
        } else if (item && item.text && item.text.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1) {
          filteredItems.push(item);
        }
      });
      if (filteredItems.length === 1) {
        filterNoResult = true;
        filteredItems.push(noSelection);
      }
    }

    this.filterNoResult = filterNoResult;
    return filteredItems;

  }

  render() {

    const placeholder = this.props.placeholder
      ? getLocalizedProperty(this.props, "placeholder")
      : intl.get("SelectField.selectAnItem").d("--Selezionare un Elemento--");
    let items = this.state.items;
    let selectedKey = "";
    let text = placeholder;

    if (this.state.isInError) {
      text = intl.get("SelectField.errorLoadingData").d("--Impossibile caricare i dati--");
      items = [{ id: "SelectFieldError", key: "SelectField.errorLoadingData", text: text }];
    } else if (!this.state.itemsLoaded) {
      text = intl.get("SelectField.loadingInProgress").d("--Caricamento dati...--");
      items = [{ id: "SelectFieldLoading", key: "SelectField.dataLoading", text: text }];
    } else {
      if (this.props.multiSelect) {
        const selectedItems = this.state.items.filter(item => item.selected);
        selectedKey = selectedItems ? selectedItems.map(item => item.key) : "";

        if (this.props.collapseSelectedItemsText === false) {
          if (selectedItems.length > 0) {
            text = selectedItems.map(item => item && item.key != null ? item.text : "").join(", ");
          }
        } else {
          text = selectedItems.length > 0 ? intl.get("SelectField.selezionati", { count: selectedItems.length }).d("" + selectedItems.length + "selezionati") : text;
        }
      } else {
        const selectedItem = this.state.items[this.state.items.findIndex((item) => item.id === this.props.value || item.key === this.props.value)];
        selectedKey = selectedItem ? selectedItem.key : "";
        text = selectedItem && selectedItem.key != null ? selectedItem.text : text;
      }
    }

    let disabled = false;
    if (!this.state.itemsLoaded || this.state.isInError) {
      disabled = true;
    } else if (this.props.forcedValue === true) {
      disabled = true;
    } else if (this.props.disabled) {
      disabled = true;
    }

    const selectFieldClasses = ["SelectField"];
    if (text === placeholder) {
      selectFieldClasses.push("PlaceHolder");
    }

    if (this.props.formFeedBack || this.props.invalid === true) {
      selectFieldClasses.push("invalidBox");
    }

    const props = {
      disabled: disabled,
      autoComplete: "on",
      multiSelect: this.props.multiSelect,
      options: this.filterItems(items),
      className: selectFieldClasses.join(" "),
      onChange: this.onChangeHandler,
      required: this.props.required,
      selectedKey: selectedKey,
      text: text,
      onMenuDismissed: this.onMenuDismissed,
      onRenderLabel: this.props.onRenderLabel,
      onMenuOpen: this.onMenuOpen
    };

    if (this.props.useLiveSearch == null || this.props.useLiveSearch === true) {
      props.onRenderOption = this._onRenderOptionHandler;
    }
    props.label = this.props.label;

    return (
      <ComboBoxWithRenderLabel {...props} />
    );
  }
}

export default SelectField;