import { createTheme } from 'office-ui-fabric-react';

const themes = {
  riparte: {
    greyBgTheme: createTheme({
      palette: {
        "themePrimary": "#0078d4",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#cccccc",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    transparentBgTheme: createTheme({
      palette: {
        "themePrimary": "transparent",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "transparent",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    darkTheme: createTheme({
      palette: {
        "themePrimary": "#eeeeee",//"#0078d4",
        "themePrimaryAlt": "#eeeeee",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#2b88d8",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#0b0b0b",
        "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#ffffff",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#000000",
        "primaryBackground": "#000000",
        "primaryText": "#ffffff",
        "bodyBackground": "#000000",
        "bodyText": "#ffffff",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    menuBarTheme: createTheme({
      palette: {
        themePrimary: '#2a376f',
        themeLighterAlt: '#2a376f',
        themeLighter: '#2a376f',
        themeLight: '#2a376f',
        themeTertiary: '#2a376f',
        themeSecondary: 'white',
        themeDarkAlt: '#2a376f',
        themeDark: '#2a376f',
        themeDarker: '#2a376f',
        neutralLighterAlt: '#2a376f',
        neutralLighter: '#2a376f',
        neutralLight: '#2a376f',
        neutralQuaternaryAlt: '#2a376f',
        neutralQuaternary: '#2a376f',
        neutralTertiaryAlt: '#2a376f',
        neutralTertiary: '#2a376f',
        neutralSecondary: '#2a376f',
        neutralPrimaryAlt: '#2a376f',
        neutralPrimary: '#2a376f',
        neutralDark: '#2a376f',
        black: '#2a376f',
        white: '#2a376f',
      }
    }),
    navBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#383939',
        neutralSecondary: 'white',
        neutralPrimaryAlt: '#a3a8a8',
        neutralPrimary: 'white',
        neutralDark: 'white',
        black: '#d8dbdb',
        white: '#00376e',
      }
    }),
    userBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#306ea6',
      }
    })
  },
  carlink: {
    greyBgTheme: createTheme({
      palette: {
        "themePrimary": "#0078d4",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#cccccc",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    transparentBgTheme: createTheme({
      palette: {
        "themePrimary": "transparent",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "transparent",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    darkTheme: createTheme({
      palette: {
        "themePrimary": "#eeeeee",//"#0078d4",
        "themePrimaryAlt": "#eeeeee",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#2b88d8",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#0b0b0b",
        "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#ffffff",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#000000",
        "primaryBackground": "#000000",
        "primaryText": "#ffffff",
        "bodyBackground": "#000000",
        "bodyText": "#ffffff",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    menuBarTheme: createTheme({
      palette: {
        themePrimary: '#ef6322',
        themeLighterAlt: '#ef6322',
        themeLighter: '#ef6322',
        themeLight: '#ef6322',
        themeTertiary: '#ef6322',
        themeSecondary: 'white',
        themeDarkAlt: '#ef6322',
        themeDark: '#ef6322',
        themeDarker: '#ef6322',
        neutralLighterAlt: '#ef6322',
        neutralLighter: '#ef6322',
        neutralLight: '#ef6322',
        neutralQuaternaryAlt: '#ef6322',
        neutralQuaternary: '#ef6322',
        neutralTertiaryAlt: '#ef6322',
        neutralTertiary: '#ef6322',
        neutralSecondary: '#ef6322',
        neutralPrimaryAlt: '#ef6322',
        neutralPrimary: '#ef6322',
        neutralDark: '#ef6322',
        black: '#ef6322',
        white: '#ef6322',
      }
    }),
    navBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#383939',
        neutralSecondary: 'white',
        neutralPrimaryAlt: '#a3a8a8',
        neutralPrimary: 'white',
        neutralDark: 'white',
        black: '#d8dbdb',
        white: '#00376e',
      }
    }),
    userBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#306ea6',
      }
    })
  },
  drgrandine: {
    greyBgTheme: createTheme({
      palette: {
        "themePrimary": "#0078d4",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#cccccc",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    transparentBgTheme: createTheme({
      palette: {
        "themePrimary": "transparent",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "transparent",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    darkTheme: createTheme({
      palette: {
        "themePrimary": "#eeeeee",//"#0078d4",
        "themePrimaryAlt": "#eeeeee",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#2b88d8",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#0b0b0b",
        "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#ffffff",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#000000",
        "primaryBackground": "#000000",
        "primaryText": "#ffffff",
        "bodyBackground": "#000000",
        "bodyText": "#ffffff",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    menuBarTheme: createTheme({
      palette: {
        themePrimary: '#19537a',
        themeLighterAlt: '#19537a',
        themeLighter: '#19537a',
        themeLight: '#19537a',
        themeTertiary: '#19537a',
        themeSecondary: 'white',
        themeDarkAlt: '#19537a',
        themeDark: '#19537a',
        themeDarker: '#19537a',
        neutralLighterAlt: '#19537a',
        neutralLighter: '#19537a',
        neutralLight: '#19537a',
        neutralQuaternaryAlt: '#19537a',
        neutralQuaternary: '#19537a',
        neutralTertiaryAlt: '#19537a',
        neutralTertiary: '#19537a',
        neutralSecondary: '#19537a',
        neutralPrimaryAlt: '#19537a',
        neutralPrimary: '#19537a',
        neutralDark: '#19537a',
        black: '#19537a',
        white: '#19537a',
      }
    }),
    navBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#383939',
        neutralSecondary: 'white',
        neutralPrimaryAlt: '#a3a8a8',
        neutralPrimary: 'white',
        neutralDark: 'white',
        black: '#d8dbdb',
        white: '#00376e',
      }
    }),
    userBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#306ea6',
      }
    })
  },
  sire: {
    greyBgTheme: createTheme({
      palette: {
        themePrimary: '#2f6af3',
        themeLighterAlt: '#f6f9fe',
        themeLighter: '#dce6fd',
        themeLight: '#bed0fb',
        themeTertiary: '#7fa3f7',
        themeSecondary: '#467af4',
        themeDarkAlt: '#295eda',
        themeDark: '#2350b8',
        themeDarker: '#1a3b88',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#f7bab5',
        neutralSecondary: '#ee7970',
        neutralSecondaryAlt: '#ee7970',
        neutralPrimaryAlt: '#e64034',
        neutralPrimary: '#e2281b',
        neutralDark: '#ac1f15',
        black: '#7f170f',
        white: '#ffffff',
      }
    }),
    transparentBgTheme: createTheme({
      palette: {
        "themePrimary": "transparent",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "transparent",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    darkTheme: createTheme({
      palette: {
        "themePrimary": "#eeeeee",//"#0078d4",
        "themePrimaryAlt": "#eeeeee",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#2b88d8",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#0b0b0b",
        "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#ffffff",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#000000",
        "primaryBackground": "#000000",
        "primaryText": "#ffffff",
        "bodyBackground": "#000000",
        "bodyText": "#ffffff",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    menuBarTheme: createTheme({
      palette: {
        themePrimary: '#003f7b',
        themeLighterAlt: '#003f7b',
        themeLighter: '#003f7b',
        themeLight: '#003f7b',
        themeTertiary: '#003f7b',
        themeSecondary: 'white',
        themeDarkAlt: '#003f7b',
        themeDark: '#003f7b',
        themeDarker: '#003f7b',
        neutralLighterAlt: '#003f7b',
        neutralLighter: '#003f7b',
        neutralLight: '#003f7b',
        neutralQuaternaryAlt: '#003f7b',
        neutralQuaternary: '#003f7b',
        neutralTertiaryAlt: '#003f7b',
        neutralTertiary: '#003f7b',
        neutralSecondary: '#003f7b',
        neutralPrimaryAlt: '#003f7b',
        neutralPrimary: '#003f7b',
        neutralDark: '#003f7b',
        black: '#003f7b',
        white: '#003f7b',
      }
    }),
    navBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#383939',
        neutralSecondary: 'white',
        neutralPrimaryAlt: '#a3a8a8',
        neutralPrimary: 'white',
        neutralDark: 'white',
        black: '#d8dbdb',
        white: '#00376e',
      }
    }),
    userBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#306ea6',
      }
    })
  },
  portal: {
    greyBgTheme: createTheme({
      palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#a2ba18',
        neutralLighter: '#9fb718',
        neutralLight: '#99b017',
        neutralQuaternaryAlt: '#8ea415',
        neutralQuaternary: '#889c14',
        neutralTertiaryAlt: '#829613',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#a5be19',
      }
    }),
    transparentBgTheme: createTheme({
      palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#a2ba18',
        neutralLighter: '#9fb718',
        neutralLight: '#99b017',
        neutralQuaternaryAlt: '#8ea415',
        neutralQuaternary: '#889c14',
        neutralTertiaryAlt: '#829613',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#a5be19',
      }
    }),
    darkTheme: createTheme({
      palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#a2ba18',
        neutralLighter: '#9fb718',
        neutralLight: '#99b017',
        neutralQuaternaryAlt: '#8ea415',
        neutralQuaternary: '#889c14',
        neutralTertiaryAlt: '#829613',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#a5be19',
      }
    }),
    menuBarTheme: createTheme({
      palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#a2ba18',
        neutralLighter: '#9fb718',
        neutralLight: '#99b017',
        neutralQuaternaryAlt: '#8ea415',
        neutralQuaternary: '#889c14',
        neutralTertiaryAlt: '#829613',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#a5be19',
      }
    }),
    navBarTheme: createTheme({
      palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#a2ba18',
        neutralLighter: '#9fb718',
        neutralLight: '#99b017',
        neutralQuaternaryAlt: '#8ea415',
        neutralQuaternary: '#889c14',
        neutralTertiaryAlt: '#829613',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#a5be19',
      }
    }),
    userBarTheme: createTheme({
      palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#a2ba18',
        neutralLighter: '#9fb718',
        neutralLight: '#99b017',
        neutralQuaternaryAlt: '#8ea415',
        neutralQuaternary: '#889c14',
        neutralTertiaryAlt: '#829613',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#a5be19',
      }
    })
  },
  "portal-clever": {
    greyBgTheme: createTheme({
      palette: {
        "themePrimary": "#0078d4",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#cccccc",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    transparentBgTheme: createTheme({
      palette: {
        "themePrimary": "transparent",//"#0078d4",
        "themePrimaryAlt": "#eff6fc",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#00376e",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#ffffff",
        "neutralLighter": "#ffffff", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#000000",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "transparent",
        "primaryBackground": "#ffffff",
        "primaryText": "#000000",
        "bodyBackground": "#ffffff",
        "bodyText": "#000000",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    darkTheme: createTheme({
      palette: {
        "themePrimary": "#eeeeee",//"#0078d4",
        "themePrimaryAlt": "#eeeeee",//"#0078d4",
        "themeLighter": "#deecf9",
        "themeLight": "#c7e0f4",
        "themeTertiary": "#eeeeee",
        "themeSecondary": "#2b88d8",
        "themeDarkAlt": "#ffffff",
        "themeDark": "#005a9e",
        "themeDarker": "#004578",
        "neutralLighterAlt": "#0b0b0b",
        "neutralLighter": "#333333", //usato anche per lo sfondo dei pulsanti active
        "neutralLight": "#252525",
        "neutralQuaternaryAlt": "#2f2f2f",
        "neutralQuaternary": "#373737",
        "neutralTertiaryAlt": "#595959",
        "neutralTertiary": "#c8c8c8",
        "neutralSecondary": "#d0d0d0",
        "neutralPrimaryAlt": "#dadada",
        "neutralPrimary": "#ffffff",
        "neutralDark": "#f4f4f4",
        "black": "#f8f8f8",
        "white": "#000000",
        "primaryBackground": "#000000",
        "primaryText": "#ffffff",
        "bodyBackground": "#000000",
        "bodyText": "#ffffff",
        "disabledBackground": "#151515",
        "disabledText": "#595959"
      }
    }),
    menuBarTheme: createTheme({
      palette: {
        themePrimary: 'rgb(146,180,206)',
        themeLighterAlt: 'rgb(146,180,206)',
        themeLighter: 'rgb(146,180,206)',
        themeLight: 'rgb(213,211,41)',
        themeTertiary: 'rgb(146,180,206)',
        themeSecondary: 'white',
        themeDarkAlt: 'rgb(146,180,206)',
        themeDark: 'rgb(146,180,206)',
        themeDarker: 'rgb(146,180,206)',
        neutralLighterAlt: 'rgb(146,180,206)',
        neutralLighter: 'rgb(146,180,206)',
        neutralLight: 'rgb(146,180,206)',
        neutralQuaternaryAlt: 'rgb(146,180,206)',
        neutralQuaternary: 'rgb(146,180,206)',
        neutralTertiaryAlt: 'rgb(146,180,206)',
        neutralTertiary: 'rgb(146,180,206)',
        neutralSecondary: 'rgb(146,180,206)',
        neutralPrimaryAlt: 'rgb(146,180,206)',
        neutralPrimary: 'rgb(146,180,206)',
        neutralDark: 'rgb(146,180,206)',
        black: 'rgb(146,180,206)',
        white: 'rgb(146,180,206)',
      }
    }),
    navBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#383939',
        neutralSecondary: 'white',
        neutralPrimaryAlt: '#a3a8a8',
        neutralPrimary: 'white',
        neutralDark: 'white',
        black: '#d8dbdb',
        white: '#00376e',
      }
    }),
    userBarTheme: createTheme({
      palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#767676',
        themeLighter: '#a6a6a6',
        themeLight: '#c8c8c8',
        themeTertiary: '#d0d0d0',
        themeSecondary: '#dadada',
        themeDarkAlt: '#eaeaea',
        themeDark: '#f4f4f4',
        themeDarker: '#f8f8f8',
        neutralLighterAlt: '#3674aa',
        neutralLighter: '#3c78ad',
        neutralLight: '#4680b3',
        neutralQuaternaryAlt: '#4d85b6',
        neutralQuaternary: '#5289b9',
        neutralTertiaryAlt: '#6a9ac5',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#306ea6',
      }
    })
  }
}

export default themes;
