import axios from "../../../axios-wagx-web";
import lodash from "lodash";
import { appCloseTab, parseString, manageError, showMessageModal, showConfirmModal, manageResponseDataMessageList, buildMessage } from '../../../functions/utility';
import { hideSearchModal } from '../../../store/actions';
import fileDownload from 'js-file-download';
import intl from 'react-intl-universal';
import contentDisposition from "content-disposition";
import { resetPageState as resetPageStateUtil, pushToPageState as pushToPageStateUtil } from '../../Page/PageUtils';
import axiosLib from "axios";
import * as ButtonActions from './ButtonActions';
import { getLocalizedProperty } from "../../../LocaleUtils";
import { reloadOpenerAndCloseTab } from '../../WagxAction/WagxActionFunction';
import { MessageSeverityEnum } from '../../../enums/MessageSeverityEnum';
import { preActionOrPersist } from "../../CRUDComponent/Actions/ActionUtils";

export const openView = (component, actionProps, value, responseObject) => {
  if (actionProps != null && actionProps.useComponentFunction === true) {
    // same logic of ActionUtils
    let propsObject = null;
    if (actionProps.useObject === true) {
      propsObject = {};
      propsObject.object = responseObject;
    }

    const onOpenViewClick = component.props.onOpenViewClick != null && typeof component.props.onOpenViewClick === "function"
      ? component.props.onOpenViewClick
      : component.onOpenViewClick;

    onOpenViewClick(propsObject, {
      objectIdField: actionProps.objectIdField,
      viewIdToOpenInDialog: actionProps.viewIdToOpenInDialog,
      loadedObject: actionProps.loadedObject,
      staticObject: actionProps.staticObject,
      modalStyle: actionProps.modalStyle,
      pushToState: actionProps.pushToState,
      applyFilters: actionProps.applyFilters,
      applyFiltersParam: actionProps.applyFiltersParam,
      destinationTitle: getLocalizedProperty(actionProps, "destinationTitle", actionProps.destinationTitle),
      formatterFieldMap: actionProps.formatterFieldMap,
      isEquals: actionProps.isEquals
    }, responseObject);
  } else {
    // old logic
    const clickedObject = { ...component.props.object };
    let loadedObject = actionProps.loadedObject ? lodash.cloneDeep(actionProps.loadedObject) : null;
    if (loadedObject != null) {
      for (const key in loadedObject) {
        //compatibiltà con portal
        let val = lodash.get(clickedObject, loadedObject[key], loadedObject[key]);
        if (val == null) {
          val = lodash.get(clickedObject, key, loadedObject[key]);
        }

        loadedObject[key] = val;
        if (typeof loadedObject[key] === "string" && actionProps.parseLoadedObject === true) {
          loadedObject[key] = parseString(loadedObject[key], clickedObject);
        }
      }
    }

    const staticObject = lodash.cloneDeep(actionProps.staticObject);
    if (staticObject != null) {
      if (loadedObject == null) {
        loadedObject = {};
      }
      for (const key in staticObject) {
        loadedObject[key] = staticObject[key];
      }
    }

    if (actionProps.passEntireObject === true) {
      loadedObject = clickedObject
    }


    if (actionProps.askIfOpenView) {
      let newActionProps = { ...actionProps };
      newActionProps.askIfOpenView = undefined;
      let dialog = component.props.confirmDialogProps;
      showMessageModal(true, dialog.title, dialog.text, true, null, () => { openView(component, newActionProps, value, responseObject) });
    } else if (actionProps.askConfirm) {
      let newaActionProps = { ...actionProps };
      newaActionProps.askConfirm = undefined;
      let dialog = component.props.confirmDialogProps;
      showConfirmModal(
        true,
        null,
        buildMessage(MessageSeverityEnum.WARNING, dialog.text),
        true,
        null,
        () => { openView(component, newaActionProps, value, responseObject) },
        () => { hideSearchModal() }
      );
    } else {
      component.props.showSearchModal(actionProps.viewIdToOpenInDialog, actionProps.modalStyle != null ? actionProps.modalStyle : null, value, responseObject != null ? responseObject : loadedObject);
    }
  }
}

export const downloadFile = (component, actionProps, value) => {
  if (actionProps.showLoadingModal) {
    component.props.showLongOperationWaitingModal();
  }
  const downloadPath = actionProps != null && actionProps.downloadPath != null ? parseString(actionProps.downloadPath, component.props.object) : "";
  const fileDownloadUrl = "/views/" + component.props.viewId + "/download/" + (actionProps != null && actionProps.downloadPath != null ? downloadPath : value);
  axios.get(fileDownloadUrl, {
    responseType: "blob"
  }).then((response) => {
    if (actionProps.showLoadingModal) {
      component.props.hideLongOperationWaitingModal();
    }
    fileDownload(response.data, contentDisposition.parse(response.headers["content-disposition"]).parameters["filename"]);
    if (actionProps.reloadData) {
      component.props.loadData();
    }
    if (actionProps.updatePageOnSuccess != null) {
      const pageState = Object.assign({}, component.props.page);
      actionProps.updatePageOnSuccess.forEach(state => {

        if (pageState[state.pageViewStateAttribute] == null) {
          pageState[state.pageViewStateAttribute] = {};
        }
        pageState[state.pageViewStateAttribute][parseString(state.viewStateAttribute, component.props.object)] = state.viewState;
      });
      component.props.setPageState(pageState);
    }
    if (actionProps.closeModalOnSuccess) {
      component.props.hiddenSearchModal();
    }
  }).catch((exception) => {
    if (actionProps.showLoadingModal) {
      component.props.hideLongOperationWaitingModal();
    }
    if (!axiosLib.isCancel(exception)) {
      manageError("ButtonActions", null, exception);
    }
  });
}

export const multipleActions = (component, multipleActionProps) => {
  for (const action in multipleActionProps) {
    const actionProps = multipleActionProps[action];
    ButtonActions[action](component, actionProps);
  }
}

export const cleanFields = (component, actionProps) => {
  const fieldsToUpdate = {};
  actionProps.fieldsToClean.forEach((field) => {
    fieldsToUpdate[field] = "";
  });
  component.props.updateFields(fieldsToUpdate);
}

export const uploadObjectData = (component, actionProps) => {
  const urlToCall = actionProps.coreFunction ? actionProps.functionName : "/views/" + component.props.viewId + "/" + actionProps.functionName;

  let object = null;
  if (component.getObject != null) {
    object = component.getObject();
  } else if (component.props.object != null) {
    object = component.props.object;
  } else if (component.state.object != null) {
    object = component.state.object;
  }

  let postObject = {
    "object": object,
    "fieldMapping": actionProps.fieldMapping
  };

  component.props.showLongOperationWaitingModal();
  axios.post(urlToCall, postObject)
    .then(response => {
      component.props.hideLongOperationWaitingModal();
      component.props.onUploadObjectDataResponseHandler
        ? component.props.onUploadObjectDataResponseHandler(response, actionProps)
        : component.onUploadObjectDataResponseHandler(response, actionProps);
    }).catch((exception) => {
      component.props.hideLongOperationWaitingModal();
      if (!axiosLib.isCancel(exception)) {
        manageError("ButtonActions", null, exception);
      }
    });
}

export const openInModal = (component, actionProps) => {
  const path = parseString(actionProps.path, component.props.object);
  const title = actionProps.modalTitle ? actionProps.modalTitle : null;
  const width = actionProps.modalWidth ? component.props.modalWidth : null;
  const height = actionProps.modalHeight ? actionProps.modalHeight : null;

  component.props.showIframeModal(path, title, width, height);
}

export const executeAction = (component, actionProps) => {
  if (actionProps.showLoadingModal) {
    component.props.showLongOperationWaitingModal();
  }

  const execute = () => {
    return new Promise((resolve, reject) => {
      if (actionProps.showLoadingModal) {
        component.props.showLongOperationWaitingModal();
      }

      return new Promise((resolve, reject) => {
        axios.post("views/" + (component.props.viewId ? component.props.viewId : component.props.view.id) + "/" + actionProps.functionName + "/" + component.props.objectId, component.props.object)
          .then(response => {
            if (actionProps.showLoadingModal) {
              component.props.hideLongOperationWaitingModal();
            }
            if (response.data.success) {
              if (actionProps.invokeOpenViewAction != null && response.data.invokeOpenViewAction) {
                openView(component, actionProps.invokeOpenViewAction, component.props.objectId, response.data.value);
              } else if (actionProps.showMessages) {
                showMessageModal(response.data.success, null, response.data.responseMessages, true);
                if (actionProps.reloadData) {
                  component.props.loadData();
                }
                if (actionProps.updatePageOnSuccess != null) {
                  const pageState = Object.assign({}, component.props.page);
                  actionProps.updatePageOnSuccess.forEach(state => {

                    if (pageState[state.pageViewStateAttribute] == null) {
                      pageState[state.pageViewStateAttribute] = {};
                    }
                    pageState[state.pageViewStateAttribute][parseString(state.viewStateAttribute, component.props.object)] = state.viewState;
                  });
                  component.props.setPageState(pageState);
                }
                if (actionProps.reloadOpenerAndCloseTab) {
                  reloadOpenerAndCloseTab();
                }
                if (actionProps.navigateOnSuccess != null) {
                  let routerState = undefined;
                  if (actionProps.keepRouterState) {
                    routerState = Object.assign({}, component.context.location != null ? component.context.location.state : component.props.location.state);
                  }
                  component.props.history.push(parseString(actionProps.navigateOnSuccess, response.data.value), routerState);
                }
                if (actionProps.updateDataTableOnSuccess != null) {
                  let dataTableState = {};
                  actionProps.updateDataTableOnSuccess.forEach(state => {
                    let viewId = null;
                    if (state.getViewIdFromUrl === true) {
                      const path = component.props.location.pathname;
                      viewId = path.substr((path.lastIndexOf("/") + 1), path.length);
                    } else {
                      viewId = state.viewId
                    }
                    if (dataTableState[viewId] == null) {
                      dataTableState[viewId] = {};
                    }
                    dataTableState[viewId] = state.viewState
                  });
                  component.props.setDataTableState(dataTableState);
                }
                if (response.data.navigateTo != null) {
                  const locationState = Object.assign({}, component.props.location.state);
                  component.props.history.push({ pathname: response.data.navigateTo, state: locationState })
                }
              }
              if (actionProps.closeModalOnSuccess) {
                component.props.hiddenSearchModal();
              }
            } else {
              if (actionProps.invokeOpenViewAction != null && response.data.invokeOpenViewAction) {
                openView(component, actionProps.invokeOpenViewAction, component.props.objectId, response.data.value);
              } else if (actionProps.showMessages) {
                showMessageModal(response.data.success, null, response.data.responseMessages, true);
              }
            }
            resolve(response);
          }).catch(exception => {
            if (actionProps.showLoadingModal) {
              component.props.hideLongOperationWaitingModal();
            }
            if (!axiosLib.isCancel(exception)) {
              manageError("ButtonActions", null, exception,
                actionProps.showMessages ? intl.get("DataTable.error.actionFailed.caption").d("Errore eseguendo un azione") : null,
                actionProps.showMessages ? manageResponseDataMessageList(false, exception.response?.data?.responseMessages).messageList : null
              );
            }
            reject(exception);
          });
      });
    });
  }

  const prePersist = () => {
    const prePersistUrlToCall = "views/PreAction/" + (component.props.viewId ? component.props.viewId : component.props.view.id) + "/" + actionProps.functionName + "/" + component.props.objectId;
    //const data = buildFormData(component.props.object);

    preActionOrPersist(null, component.props.object, prePersistUrlToCall, execute, (title, message, showConfirmModal = true) => {
      if (actionProps.showLoadingModal) {
        component.props.hideLongOperationWaitingModal();
      }
      
      if(showConfirmModal === true) {
        component?.props?.sendDataConfirmDialog(message, execute);
      }
    });
  }

  if (actionProps.prePersist === true) {
    return new Promise((resolve, reject) => resolve(prePersist()));
  } else {
    return execute();
  }
}

export const nativeHref = (component, actionProps) => {
  window.location.href = parseString(actionProps.href, component.props.object);
}

export const closeTab = (component, actionProps) => {
  appCloseTab();
}

export const pushToPageState = (component, actionProps) => {
  const routerState = Object.assign({}, component.context.location != null ? component.context.location.state : component.props.location.state);
  pushToPageStateUtil(component.props, routerState, actionProps);
}
export const resetPageState = (component, actionProps) => {
  const routerState = Object.assign({}, component.context.location != null ? component.context.location.state : component.props.location.state);
  resetPageStateUtil(component.props, routerState, actionProps);
}

export const showSidePanel = (component, actionProps) => {
  component.props.showSidePanel(actionProps.sidePanelName);
}

export const navigateTo = (component, actionProps) => {
  const targetUrl = parseString(actionProps.targetUrl, component.props.object);
  const { history } = component.props;
  const { location } = component.context ? component.context : component.props;
  const state = Object.assign({}, location.state);
  history.push(targetUrl, state);
}

export const submit = (component, actionProps) => {
  const objectId = component.props.objectId;
  const url = component.props.restBaseUrl;
  const object = component.props.object;

  if (component.props.onSubmit != null) {
    component.props.onSubmit(object, null, objectId, url);
  } else {
    console.warn("errore di configurazione onSubmit non trovato!");
  }

}
