import React from 'react';
import intl from 'react-intl-universal';
import { stringDateLocalDateFormat, stringDateLocalTimeFormat } from '../../functions/formatters';

const UserInfo = (props) => {
  let insertedByMessage = null;
  let insertedAtMessage = null;
  let updatedByMessage = null;
  let updatedAtMessage = null;

  let messages = [];

  if (props.aUserIns != null) {
    const obj = {
      aUserIns: props.aUserIns
    };
    insertedByMessage = intl.getHTML("UsersInfo.insertedByUser", obj).d("Inserito da: " + obj.aUserIns);
  }
  if (props.dDataIns != null) {
    insertedAtMessage = stringDateLocalDateFormat(props.dDataIns) + " " + stringDateLocalTimeFormat(props.dDataIns);
  }
  if (insertedByMessage != null || insertedAtMessage != null) {
    messages.push(<label>{insertedByMessage} {insertedAtMessage}</label>);
  }


  if (props.aUserMod != null) {
    const obj = {
      aUserMod: props.aUserMod
    };
    updatedByMessage = intl.getHTML("UsersInfo.modifiedByUser", obj).d("Modificato da: " + obj.aUserMod);
  }
  if (props.dDataMod != null) {
    updatedAtMessage = stringDateLocalDateFormat(props.dDataMod) + " " + stringDateLocalTimeFormat(props.dDataMod);
  }
  if (updatedByMessage != null || updatedAtMessage != null) {
    messages.push(<label>{updatedByMessage} {updatedAtMessage}</label>);
  }

  if (Array.isArray(props.otherMessages)) {
    messages = [...messages, ...props.otherMessages];
  } else if (props.otherMessages != null) {
    messages.push(props.otherMessages);
  }

  return (
    <React.Fragment>
      {messages.map((item,i) => <React.Fragment key={i}>{item}<br /></React.Fragment>)}
    </React.Fragment>
  );
}
export default UserInfo;