import React, { PureComponent } from 'react';
import axios from '../../axios-wagx-web';
import { IconButton } from 'office-ui-fabric-react';
import Spinner from '../../UI/Spinner/Spinner';
import './IconSwitchComponent.css';
import * as evaluateFunctions from '../../functions/utility';
import { connect } from 'react-redux';
import { setPageState } from '../../store/actions';
import { isNotNullOrEmpty } from '../../functions/StringUtils';

class IconSwitchComponent extends PureComponent {
  constructor(props) {
    super(props);
    //Inizializzazione STATE
    // eslint-disable-next-line eqeqeq
    const iconName = this.props.object[this.props.field] == 1 ? this.props.favouriteIcon : this.props.notFavouriteIcon;

    this.state = {
      iconName: iconName,
      changing: false,
      className: evaluateFunctions.parseString(props.className, this.props.object)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.object[this.props.field] !== prevProps.object[this.props.field]) {
      // eslint-disable-next-line eqeqeq
      const iconName = this.props.object[this.props.field] == 1 ? this.props.favouriteIcon : this.props.notFavouriteIcon;
      this.setState({ iconName: iconName })
    }
  }

  updatePageOnCondition = (response) => {
    if (response.data.success === true && this.props.updatePageOnCondition != null) {
      let pageState = {};
      this.props?.updatePageOnCondition?.forEach(state => {
        let evalState = true;
        if (isNotNullOrEmpty(state.condition)) {
          const funcBody = evaluateFunctions.computeFunctionBody(state.condition);
          // eslint-disable-next-line
          const func = new Function('response', funcBody);
          evalState = func(response);
        }

        if (evalState === true) {
          if (pageState[state.pageViewStateAttribute] == null) {
            pageState[state.pageViewStateAttribute] = {};
          }
          if (state.viewStateAttribute) {
            pageState[state.pageViewStateAttribute][state.viewStateAttribute] = state.viewState;
          } else {
            pageState[state.pageViewStateAttribute] = state.viewState
          }
        }
      });
      this.props.setPageState(pageState);
    }
  }


  onIconSwitchChange = (props) => {
    this.setState({ changing: true }, () => {
      const changeIconSwitchUrl = "/views/" + props.viewId + "/" + props.functionName;
      axios.post(changeIconSwitchUrl, props.object).then((response) => {

        let iconName = this.state.iconName;
        let className = this.state.className;
        let callUpdatePageOnConditionAfterSetState = true;
        if (response.data.success === true) {
          if (this.props.reloadData) {
            props.loadData();
          }

          if (Array.isArray(response.data.responseMessages) && response.data.responseMessages.length > 0) {
            callUpdatePageOnConditionAfterSetState = false;
            evaluateFunctions.showMessageModal(response.data.success, null, response.data.responseMessages, true, null, () => {
              this.updatePageOnCondition(response);
            });
          }

          // eslint-disable-next-line
          iconName = response.data.value[props.field] == 1 ? props.favouriteIcon : props.notFavouriteIcon;
          className = evaluateFunctions.parseString(props.className, response.data.value);
        } else {
          evaluateFunctions.showMessageModal(response.data.success, null, response.data.responseMessages, true);
        }

        this.setState({
          changing: false,
          iconName: iconName,
          className: className
        }, () => {
          if (this.props.onChange && response.data.success === true) {
            this.props.onChange(null, this.props.field, response.data.value[this.props.field]);
          }

          if(callUpdatePageOnConditionAfterSetState === true) {
            this.updatePageOnCondition(response);
          }
        });
      }).catch((exception) => {
        evaluateFunctions.manageError("IconSwitch - onIconSwitchChange", null, exception);
        this.setState({ changing: false });
      });
    })
  }

  render() {
    return (
      <div className="IconSwitchComponent">
        {
          this.state.changing ?
            <Spinner /> :
            <IconButton
              disabled={this.props.disabled}
              onClick={() => this.onIconSwitchChange(this.props)}
              iconProps={{ iconName: this.state.iconName }}
              className={this.state.className}
            />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    page: state.page
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setPageState: (state) => dispatch(setPageState({ state: state }))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IconSwitchComponent);