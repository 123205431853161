import React from 'react';
import * as actionTypes from './ActionTypes';
import * as ComponentTypes from '../ComponentTypes';
import * as DataTableTypes from '../../DataTable/DataTableTypes';
import * as ButtonActions from "../../GenericForm/ButtonActions/ButtonActions";
import { getLocalizedProperty } from '../../../LocaleUtils';
import { parseStringFromApplyFilters, clickLinkHandler, parseConditionStringFromApplyFilters, manageError, showMessageModal, buildMessage, computeFunctionBody } from '../../../functions/utility';
import intl from 'react-intl-universal';
import { parseConditionString, parseString } from '../../../functions/utility';
import { pushToPageState, resetPageState } from '../../Page/PageUtils';
import { store } from '../../../../store-config';
import axios from '../../../axios-wagx-web';
import axiosLib from "axios";
import { MessageSeverityEnum } from '../../../enums/MessageSeverityEnum';
import { hideSearchModal } from '../../../store/actions';
import UserInfo from '../../UserInfo/UserInfo';

const linkClickHandler = (event, item, component) => {
  const props = item.action;

  const path = parseStringFromApplyFilters(props.path, component.props.applyFilters != null ? component.props.applyFilters : component.props.viewState != null ? component.props.viewState.applyFilters : null);

  clickLinkHandler(path, props, component);
}

export const getDefaultActions = (selectedItem, fromLink, component) => {
  let defaultActions = [];
  if (fromLink && (component.props.showGoBack == null || component.props.showGoBack)) {
    defaultActions = defaultActions.concat([
      {
        key: "back",
        name: intl.get("ActionUtils.back").d('Indietro'),
        iconProps: {
          iconName: 'NavigateBack'
        },
        iconOnly: false,
        onClick: () => component.props.history.goBack()
      }
    ]);
  }
  if (component.props.masterView && (component.props.allowAddNew === undefined || component.props.allowAddNew === true)) {
    defaultActions = defaultActions.concat([
      {
        key: "new",
        name: intl.get("ActionUtils.new").d('Nuovo'),
        iconProps: {
          iconName: 'CircleAddition'
        },
        iconOnly: false,
        onClick: () => component.props.showDetailHandler("New")
      }
    ]);
  }
  if (component.props.viewState != null && component.props.viewState.filters != null && Object.keys(component.props.viewState.filters).length > 0) {
    //Eseguo operazioni differenti in base al tipo di componente
    let actionName;
    let iconName;
    switch (component.props.type) {
      case ComponentTypes.DATA_TABLE:
        //Eseguo operazioni differenti in base al tipo di ricerca impostato
        switch (component.props.searchMode) {
          case DataTableTypes.FILTER: ;
            actionName = intl.get("ActionUtils.removeFilters").d('Rimuovi Filtri');
            iconName = "ClearFilter";
            break;
          case DataTableTypes.SEARCH:
            actionName = intl.get("ActionUtils.reset").d('Reimposta');
            iconName = "RemoveOccurrence";
            break;
          default:
            actionName = intl.get("ActionUtils.removeFilters").d('Rimuovi Filtri');
            iconName = "ClearFilter";
            break;
        }
        break;
      default:
        //actionName = intl.get("ActionUtils.removeFilters").d('Rimuovi Filtri');
        //iconName = "ClearFilter";
        break;
    }
    if (actionName !== undefined) {
      defaultActions = defaultActions.concat([
        {
          key: "delFltr",
          name: actionName,
          iconProps: {
            iconName: iconName
          },
          iconOnly: false,
          onClick: () => component.onClearAllFiltersHandler()
        }
      ]);
    }
  }
  if (selectedItem != null) {
    defaultActions = defaultActions.concat([
      {
        key: "del",
        name: intl.get("ActionUtils.delete").d('Elimina'),
        iconProps: {
          iconName: 'Delete'
        },
        iconOnly: false,
        onClick: () => component.props.deleteItemHandler(selectedItem)
      }]);
  }
  return defaultActions;
}
export const getCommandBarActions = (actions, component, object = null, replacementMap = null) => {
  let cmbActions = [];
  if (actions) {
    actions.forEach((action, index) => {

      let showAction = true;
      let showIfProfileIn = true;
      //Se l'action ha definito uno "showIf"
      if (action.showIf) {
        let parsedString = replacementMap ? parseConditionString(action.showIf, replacementMap, null, false) : action.showIf;
        if (object != null) {
          try {
            parsedString = parseConditionString(parsedString, object, null, false);
            // eslint-disable-next-line
            showAction = eval(parsedString);
          } catch (e) {
            showAction = true;
          }
        }

        if ((component.props.viewState != null ? component.props.viewState.applyFilters : null) != null) {
          try {
            parsedString = parseConditionStringFromApplyFilters(parsedString, component.props.viewState != null ? component.props.viewState.applyFilters : null);
            // eslint-disable-next-line
            showAction = eval(parsedString);
          } catch (e) {
            showAction = true;
          }
        }
      }

      let isEnabled = true;
      if (action.enableIf != null) {
        let functionBody = computeFunctionBody(action.enableIf)
        try {
          // eslint-disable-next-line 
          const func = new Function("component", "object", functionBody);
          isEnabled = func(component, object, functionBody);
        } catch (e) {
          throw new Error(e.message + " ENABLEIF Probabile errore di sinstassi " + JSON.stringify(functionBody));
        }
      }

      if (action.showIfEmptyItems != null) {
        if (action.showIfEmptyItems) {
          showAction = component.state.items != null && component.state.items.length === 0;
        } else {
          showAction = component.state.items != null && component.state.items.length > 0;
        }
      }

      //se ho definito showProfileIn
      if (action.showIfProfileIn) {
        try {
          var found = false;
          for (let i = 0; i < action.showIfProfileIn.length; i++) {
            if (store.getState().auth.appState.profiles.includes(action.showIfProfileIn[i].trim().toUpperCase())) {
              found = true;
              break;
            }
          }
          showIfProfileIn = found;
        } catch (e) {
          showIfProfileIn = false;
        }
      }

      if (showAction && showIfProfileIn) {

        let actionItem = {};
        if (action.type === actionTypes.INSERT) {

          actionItem = {
            key: 'newItem"_' + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: 'CircleAddition'
            },
            //className: "ms-bgColor-themeLight--hover ms-fontColor-themePrimary",
            onClick: action.handler
          };
        } else if (action.type === actionTypes.EXPORT && component.exportDataHandler) {
          const totalElements = component.state.page ? component.state.page.totalElements : null;
          actionItem = {
            key: actionTypes.EXPORT+"_"+ component?.props?.name + "_" + index,
            name: intl.get("ActionUtils.export").d('Export'),
            iconProps: {
              iconName: 'DownloadDocument'
            },
            iconOnly: false,
            subMenuProps: {
              items: [
                {
                  key: 'csvExport',
                  iconProps: {
                    iconName: "DataConnectionLibrary"
                  },
                  text: intl.get("ActionUtils.exportToCsv").d('Esportazione in CSV'),
                  onClick: (event) => component.exportDataHandler(event, totalElements, "CSV")
                },
                {
                  iconProps: {
                    iconName: "ExcelLogo"
                  },
                  key: 'xlsxExport',
                  text: intl.get("ActionUtils.exportToExcel").d('Esportazione in Excel'),
                  onClick: (event) => component.exportDataHandler(event, totalElements, "XLSX")
                }
              ]
            }
          }
        } else if (action.type === actionTypes.REFRESH) {
          actionItem = {
            key: actionTypes.REFRESH + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: 'Refresh'
            },
            onClick: () => component.refreshHandler()
          }
        } else if (action.type === actionTypes.TAB_REFRESH) {
          actionItem = {
            key: actionTypes.TAB_REFRESH + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.iconName != null ? action.iconName : "Refresh"
            },
            onClick: () => { window.location.reload() }
          }
        } else if (action.type === actionTypes.USER_LOG) {

          const obj = {}
          if (object != null) {
            if (action.actionProps.insertedBy != null && object[action.actionProps.insertedBy] != null) {
              obj.aUserIns = object[action.actionProps.insertedBy];
            }
            if (action.actionProps.insertedAt != null && object[action.actionProps.insertedAt] != null) {
              obj.dDataIns = object[action.actionProps.insertedAt];
            }
            if (action.actionProps.updatedBy != null && object[action.actionProps.updatedBy] != null) {
              obj.aUserMod = object[action.actionProps.updatedBy];
            }
            if (action.actionProps.updatedAt != null && object[action.actionProps.updatedAt] != null) {
              obj.dDataMod = object[action.actionProps.updatedAt];
            }
          }

          const tooltipMessage = (<UserInfo {...obj} />);

          actionItem = {
            key: actionTypes.USER_LOG + "_" + component?.props?.name + "_" + index,
            name: null,
            iconProps: {
              iconName: 'Info'
            },
            tooltipMessage: tooltipMessage,
            onClick: () => { }
          }
        } else if (action.type === actionTypes.CUSTOM_LOAD_DATA) {
          actionItem = {
            key: actionTypes.CUSTOM_LOAD_DATA + action.functionName + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.icon
            },
            onClick: () => component.loadData(action.functionName, true)
          }
        } else if (action.type === actionTypes.CUSTOM_DATA_SEND) {
          let className = action.className ? parseConditionString(action.className, object, null, false) : "";
          actionItem = {
            key: actionTypes.CUSTOM_DATA_SEND + "_" + action.functionName + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            action: action,
            className: className,
            disabled: isEnabled === false,
            iconProps: {
              iconName: action.icon
            },
            onClick: () => {
              if (action.confirmMessage != null) {
                component.sendDataConfirmDialog(action, null, null); // Niente callback, ci pensa già la sendDataConfirmDialog in caso di action.
              } else {
                if (action.preAction) {
                  component.sendDataPreActionDialog(action, null);
                }
                component.sendData(action, null);
              }
            }
          }
        } else if (action.type === actionTypes.CUSTOM_DATA_SEND_EXT) {
          actionItem = {
            key: actionTypes.CUSTOM_DATA_SEND_EXT + action.functionName + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            action: action,
            iconProps: {
              iconName: action.icon
            },
            onClick: () => {
              if (component._selection != null && component._selection.getSelectedIndices().length > 0) {
                const loadedObject = component.getSelectedRows(action.selectionFieldConfig.fieldList);
                component.onOpenViewClick(null, {
                  viewIdToOpenInDialog: action.viewIdToOpenInDialog,
                  loadedObject: loadedObject,
                  modalStyle: action.modalStyle
                })
              } else {
                const message = buildMessage(MessageSeverityEnum.INFO, "DataTable.info.selectSomeRows.text");
                showMessageModal(false, null, message, false);
              }
            }
          }
        } else if (action.type === actionTypes.ENGINE_WORKFLOW) {
          actionItem = {
            key: actionTypes.ENGINE_WORKFLOW + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.icon
            },
            onClick: () => {
              if (component.prepareEngineWorkflow == null) {
                console.warn("prepareEngineWorkflow not defined for component: ", component);
              } else {
                component.prepareEngineWorkflow(action);
              }
            }
          }
        } else if (action.type === actionTypes.EXECUTE_FUNCTION && component.executeFunctionHandler) {
          actionItem = {
            key: actionTypes.EXECUTE_FUNCTION + action.functionName + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.icon
            },
            onClick: () => component.executeFunctionHandler(action.functionName, action.showMessages, action.returnsFile, action)
          }
        } else if (action.type === actionTypes.LINK) {
          let filterObjectToEvalutate = null;
          let className = action.actionClassName ? parseConditionString(action.actionClassName, object, null, false) : "";
          let text = getLocalizedProperty(action, "text", null, object);

          if (component.props.applyFilters != null) {
            filterObjectToEvalutate = component.props.applyFilters;
          } else if (component.props.viewState != null) {
            filterObjectToEvalutate = component.props.viewState.applyFilters;
          }

          if (filterObjectToEvalutate != null) {
            className = parseConditionStringFromApplyFilters(className, filterObjectToEvalutate);
            text = parseConditionStringFromApplyFilters(text, filterObjectToEvalutate);
          }

          const newAction = { ...action }
          actionItem = {
            className: className,
            key: "ACTION_TYPE_INDEX_"+index + "_" + component?.props?.name,
            name: text != null && text !== "null" ? text : "",
            action: newAction,
            iconProps: {
              iconName: action.iconName != null ? action.iconName : 'Link'
            },
            onClick: (event) => action.confirmMessage ? 
              component.sendDataConfirmDialog(newAction, null, () => component.linkClickHandler(event, actionItem)) 
              : component.linkClickHandler
                ? component.linkClickHandler(event, actionItem, object) : linkClickHandler(event, actionItem, component)
          };
        } else if (action.type === actionTypes.FORM_LINK) {
          actionItem = {
            key: component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text", null, object),
            action: action,
            iconProps: {
              iconName: action.iconName != null ? action.iconName : 'Link'
            },
            onClick: (event) => {
              formLink(object, action);
              if (action.closeOnClick) {
                component.hideSearchModal();
              }
            }
          };
        } else if (action.type === actionTypes.OPEN_VIEW) {
          let propsObject = null;
          let name = getLocalizedProperty(action, "text");
          if (action.useObject === true) {
            propsObject = {};
            propsObject.object = object;

            if (object != null) {
              name = parseString(name, object);
            } else if (component.props != null && component.props.data != null) {
              name = parseString(name, component.props.data[0]);
            }
          }

          actionItem = {
            key: 'openView_' + component?.props?.name + "_" + index,
            name: name,
            iconProps: {
              iconName: action.icon
            },
            onClick: (object) => component.onOpenViewClick(propsObject, {
              objectIdField: action.objectIdField,
              viewIdToOpenInDialog: action.viewIdToOpenInDialog,
              loadedObject: action.loadedObject,
              loadedObjectExtended: action.loadedObjectExtended,
              modalStyle: action.modalStyle,
              pushToState: action.pushToState,
              applyFiltersParam: action.applyFiltersParam,
              destinationTitle: getLocalizedProperty(action, "destinationTitle"),
              formatterFieldMap: action.formatterFieldMap,
              isEquals: action.isEquals
            }, object)
          };
        } else if (action.type === actionTypes.PUSH_TO_PAGE_STATE) {
          actionItem = {
            key: 'pushToPageState_' + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.icon
            },
            onClick: () => {
              const routerState = Object.assign({}, component.context.location != null ? component.context.location.state : component.props.location.state);
              pushToPageState({ ...component.props, ...component.context }, routerState, action, object, component.props.applyFilters != null ? component.props.applyFilters : component.props.viewState != null ? component.props.viewState.applyFilters : null);
            }
          };
        } else if (action.type === actionTypes.RESET_PAGE_STATE) {
          actionItem = {
            key: 'pushToPageState_' + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.icon
            },
            onClick: () => {
              resetPageState(component.props, action);
            }
          };
        } else if (action.type === actionTypes.BACK_TO_HOME_OPERATIVA) {
          actionItem = {
            key: "backToHomeOperativa_" + component?.props?.name + "_" + index,
            name: intl.get("ActionUtils.GoToHomeOperativa").d('Torna a Operatività'),
            iconProps: {
              iconName: action.iconProps.iconName
            },
            iconOnly: false,
            onClick: () => {
              // [action.viewStateAttribute];
              let state = null;
              if (component.context.location != null && component.context.location.state != null) {
                state = Object.assign({}, component.context.location.state);
              } else if (component.props.location != null && component.props.location.state != null) {
                state = Object.assign({}, component.props.location.state);
              }

              if (state == null) {
                component.props.history.push(action.homeOperativaUrl);
              } else {
                if (component.props.homeOperativaState != null) {
                  component.props.history.push(component.props.homeOperativaState.location, state);
                } else {
                  component.props.history.push(action.homeOperativaUrl, state);
                }
              }
            }
          }
        } else if (action.type === actionTypes.PAGE_REFRESH) {
          actionItem = {
            key: "pageRefresh_" + component?.props?.name + "_" + index,
            name: intl.get("GenericAction.refresh").d('Aggiorna'),
            iconProps: {
              iconName: action.iconName
            },
            iconOnly: false,
            onClick: () => {
              if (action.executeAction != null) {
                component.executeFunctionHandler(action.executeAction, action.showMessages).then(data => {
                  let pageState = {};
                  action.refreshStates.forEach(state => {
                    if (pageState[state.pageViewStateAttribute] == null) {
                      pageState[state.pageViewStateAttribute] = {};
                    }

                    if (Array.isArray(state.viewStateAttribute) === true) {
                      state.viewStateAttribute.forEach(viewState => {
                        pageState[state.pageViewStateAttribute][viewState] = state.viewState;
                      })
                    } else {
                      pageState[state.pageViewStateAttribute][state.viewStateAttribute] = state.viewState;
                    }
                  });
                  component.props.setPageState(pageState);
                }).catch((exception) => {
                  manageError("ActionUtils", null, exception);
                  let pageState = {};
                  action.refreshStates.forEach(state => {
                    if (pageState[state.pageViewStateAttribute] == null) {
                      pageState[state.pageViewStateAttribute] = {};
                    }
                    pageState[state.pageViewStateAttribute][state.viewStateAttribute] = state.viewState;
                  });
                  component.props.setPageState(pageState);
                });
              } else {
                let pageState = {};
                action.refreshStates.forEach(state => {
                  if (pageState[state.pageViewStateAttribute] == null) {
                    pageState[state.pageViewStateAttribute] = {};
                  }
                  pageState[state.pageViewStateAttribute][state.viewStateAttribute] = state.viewState;
                });
                component.props.setPageState(pageState);
              }
            }
          }
        } else if (action.type === actionTypes.OPEN_IN_MODAL) {
          let optionalObject = {}
          if (action.objectIdField) {
            optionalObject[action.objectIdField] = component.props.objectId;
          }
          let parsedPath = parseStringFromApplyFilters(action.path, component.props.applyFilters != null ? component.props.applyFilters : component.props.viewState != null ? component.props.viewState.applyFilters : null, false);
          parsedPath = action.objectIdField ? parseString(parsedPath, null, null, optionalObject) : parsedPath;
          parsedPath = object ? parseString(parsedPath, object) : parsedPath;
          let className = action.actionClassName ? parseConditionString(action.actionClassName, object, null, false) : "";
          if (component.props.applyFilters != null ? component.props.applyFilters : component.props.viewState != null ? component.props.viewState.applyFilters : null) {
            className = parseConditionStringFromApplyFilters(className, component.props.applyFilters != null ? component.props.applyFilters : component.props.viewState != null ? component.props.viewState.applyFilters : null);
          }
          if (action.updatePageOnClose) {
            action.updatePageOnClose.forEach((element, index) => {
              if (!action.updatePageOnClose[index].viewStateAttribute) {
                action.updatePageOnClose[index].viewStateAttribute = action.updatePageOnClose[index].viewStateAttribute ? action.updatePageOnClose.viewStateAttribute : component.props.viewStateAttribute;
              }
            });
          }
          actionItem = {
            key: "openInModal_" + component?.props?.name + "_" + index,
            className: className,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.iconName
            },
            iconOnly: false,
            onClick: () => {
              component.props.showIframeModal(parseStringFromApplyFilters(parsedPath, component.props.viewState != null ? component.props.viewState.applyFilters : []), action.title ? action.title : null, action.width, action.height, action.updatePageOnClose);
            }
          }
        } else if (action.type === actionTypes.ACTION_BUTTON) {

          action.onClick = () => ButtonActions[action.buttonAction](component, action.actionProps);

          actionItem = {
            key: "actionButton_" + component?.props?.name + "_" + index,
            className: action.className,
            name: getLocalizedProperty(action.title, "text"),
            iconProps: {
              iconName: action.iconProps.iconName
            },
            onClick: () => {
              action.onClick();
            }
          }

        } else if (action.type === actionTypes.FILE_DOWNLOAD && component.onFileDownloadClick) {
          const fieldValue = parseStringFromApplyFilters(action.applyFiltersField, component.props.applyFilters != null ? component.props.applyFilters : component.props.viewState != null ? component.props.viewState.applyFilters : null);
          const viewId = action.viewId != null ? action.viewId : component.props.id;
          actionItem = {
            key: actionTypes.FILE_DOWNLOAD + "_" + action.name + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            iconProps: {
              iconName: action.iconName != null ? action.iconName : 'DownloadDocument'
            },
            onClick: () => {
              component.onFileDownloadClick(viewId, fieldValue);
            }
          }
        } else if (action.type === actionTypes.SUB_MENU_ACTIONS) {
          let subActions = getCommandBarActions(action.subActions, component, object, replacementMap);
          if (Array.isArray(subActions) === false) {
            subActions = [];
          }

          actionItem = {
            key: actionTypes.SUB_MENU_ACTIONS + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "title"),
            text: getLocalizedProperty(action, "text"),
            iconProps: action.iconProps,
            className: action.className,
            subMenuProps: {
              items: subActions.map((subAction, index) => {
                const currentSubAction = subAction.action != null
                  ? subAction.action
                  : subAction;
                const text = currentSubAction.text != null
                  ? currentSubAction.text
                  : currentSubAction.name;

                return {
                  key: index,
                  text: text,
                  iconProps: subAction.iconProps,
                  className: currentSubAction.className,
                  onClick: subAction.onClick
                }
              })
            }
          }
        } else if (action.type === actionTypes.CLOSE_MODAL) {
          actionItem = {
            key: actionTypes.SUB_MENU_ACTIONS + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            className: action.className,
            onClick: () => store.dispatch(hideSearchModal())
          }
        } else if (action.type === actionTypes.PROPS_FUNCTION) {
          actionItem = {
            key: actionTypes.PROPS_FUNCTION + "_" + action.functionName + "_" + component?.props?.name + "_" + index,
            name: getLocalizedProperty(action, "text"),
            className: action.className,
            iconProps: {
              iconName: action.iconName
            },
            onClick: () => {
              const functionToExecute = component.props[action.functionName];

              if (functionToExecute == null) {
                console.warn("'" + action.functionName + "' not found in props for component: ", component);
              } else {
                functionToExecute(action);
              }
            }
          };
        }
        if (actionItem != null) {
          cmbActions.push(actionItem);
        }
      }
    });
  }
  return cmbActions;
}

export const formLink = (object, props) => {
  var form = document.createElement("form");
  form.method = props.method;
  form.target = props.target;
  form.action = props.action;

  props.inputs.forEach(input => {
    var formInput = document.createElement("input");

    const parsedValue = parseString(input.evalValue != null ? input.evalValue : input.value, object);
    // eslint-disable-next-line
    formInput.value = input.evalValue != null ? eval(parsedValue) : parsedValue;
    formInput.type = "hidden";
    formInput.name = input.name;
    form.appendChild(formInput);
  });
  document.body.appendChild(form);
  form.submit();
}

export const preActionOrPersist = (keyLocalization, data, url, callback, componentSetState) => {
  axios.post(url, data)
    .then((response) => {
      if (response.data.validationError) {
        const message = [];
        if (response.data.errorMessageCode != null) {
          const messageParameters = response.data.errorMessageCode != null ? response.data.errorMessageParameters : null;
          message.push(buildMessage(MessageSeverityEnum.ERROR, response.data.errorMessageCode, messageParameters));
        } else if (Array.isArray(response.data.errorMessages)) {
          response.data.errorMessages.forEach(errorMessage => {
            message.push(buildMessage(MessageSeverityEnum.ERROR, errorMessage));
          });
        }
        showMessageModal(false, null, message, true);
        componentSetState(null, null, false);
      } else if (response.data.needUserConfirm) {
        const title = response.data.confirmTitle ? intl.get(response.data.confirmTitle).d(response.data.confirmTitle) : null;
        const message = response.data.confirmMessages.map(confirmMessage =>
          <p>{intl.get(confirmMessage.messageKey, confirmMessage.parameters).d(confirmMessage.messageKey)}</p>
        );
        componentSetState(title, message);
      } else {
        callback();
      }
    }).catch((exception) => {
      if (!axiosLib.isCancel(exception)) {
        manageError("ActionUtils", null, exception,
          intl.get("ActionUtils.validationFailed.title").d('Esito validazione'),
          intl.get("ActionUtils.validationFailed.text").d('Validazione NON riuscita!')
        );
      }
    });
}
