//import moment from 'moment-with-locales-es6';
import moment from 'moment-timezone';
import intl from 'react-intl-universal';

export const getDayPickerStrings = (ld = null) => {
  let localeDate = ld;
  if(localeDate == null){
    localeDate = moment.localeData();
  }

  const dayPickerStrings = {
    months: localeDate.months(),
    shortMonths: localeDate.monthsShort(),
    days: localeDate.weekdays(),
    shortDays: localeDate.weekdaysMin(),

    goToToday: intl.get("DatePicker.goToToday").d('Go to today'),
    prevMonthAriaLabel: intl.get("DatePicker.prevMonthAriaLabel").d('Go to previous month'),
    nextMonthAriaLabel: intl.get("DatePicker.nextMonthAriaLabel").d('Go to next month'),
    prevYearAriaLabel: intl.get("DatePicker.prevYearAriaLabel").d('Go to previous year'),
    nextYearAriaLabel: intl.get("DatePicker.nextYearAriaLabel").d('Go to next year'),
    closeButtonAriaLabel: intl.get("DatePicker.closeButtonAriaLabel").d('Close date picker'),

    isRequiredErrorMessage: intl.get("DatePicker.isRequiredErrorMessage").d('Start date is required.'),

    invalidInputErrorMessage: intl.get("DatePicker.invalidInputErrorMessage").d('Invalid date format.'),
  };

  return dayPickerStrings;
}
