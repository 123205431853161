import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { TextField, Label } from 'office-ui-fabric-react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import * as GooglePlacesTypes from './GooglePlacesTypes';
import imgGoogleLogo from './google-logo.png';
import './GooglePlacesAutocomplete.css';
import { getLocalizedProperty } from '../../LocaleUtils';

class GooglePlacesAutocomplete extends Component {
  constructor(props) {
    super(props);

    //Creo un oggetto dinamico contenente le field da gestire
    const selectedAddressObject = {};
    selectedAddressObject[this.props.field] = this.props.value != null ? this.props.value : '';
    if (this.props.updateGeocodeFields) {
      this.props.updateGeocodeFields.forEach(updateGeocodeField => {
        selectedAddressObject[updateGeocodeField.field] = '';
      });
    }

    this.state = {
      selectedAddressObject: selectedAddressObject
    }

    this.cleanStreetNumberFromAddress = this.props.cleanStreetNumberFromAddress === true;
    this.blankOnPlaceSelected = this.props.blankOnPlaceSelected === true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this._onChangeInputHandler(this.props.value);
    }
  }

  _onChangeInputHandler = (indirizzo) => {
    const selectedAddressObject = { ...this.state.selectedAddressObject };

    selectedAddressObject[this.props.field] = indirizzo;

    this.setState({
      selectedAddressObject: selectedAddressObject
    }, () => {
      if (this.props.onChange != null) {
        this.props.onChange(null, this.props.field, this.state.selectedAddressObject[this.props.field], null, this.props.formId);
      }
    });
  };

  _onSelectInputHandler = (address, placeId) => {
    if (placeId != null) {
      geocodeByPlaceId(placeId)
        .then(results => this.getSelectedAddressObjectFromSelection(results, address))
        .catch(error => console.error('Error', error));
    }
  };

  _onBlurHandler = () => {
    if (this.props.onChange != null) {
      this.props.onChange(null, this.props.field, this.state.selectedAddressObject[this.props.field], null, this.props.formId);
    }
  };

  getSelectedAddressObjectFromSelection = (results, address) => {
    const result = results && results[0].geometry ? results[0] : null;
    if (!result) {
      return result;
    }

    const selectedAddressObject = { ...this.state.selectedAddressObject };
    for (const key in selectedAddressObject) {
      selectedAddressObject[key] = '';
    }

    let streetNumberFound = false;
    result.address_components.forEach(address_component => {
      switch (address_component.types[0]) {
        case 'country':
          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.NAZIONE) {
                selectedAddressObject[updateGeocodeField.field] = address_component.long_name;
              } else if (updateGeocodeField.type === GooglePlacesTypes.CODICE_NAZIONE) {
                selectedAddressObject[updateGeocodeField.field] = address_component.short_name;
              }
            });
          }
          break;
        case 'administrative_area_level_1':
          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.REGIONE) {
                selectedAddressObject[updateGeocodeField.field] = address_component.short_name;
              }

              if (updateGeocodeField.type === GooglePlacesTypes.COMUNE) {
                if (selectedAddressObject[updateGeocodeField.field] == null || ("" + selectedAddressObject[updateGeocodeField.field]).trim() === "") {
                  selectedAddressObject[updateGeocodeField.field] = address_component.long_name;
                }
              }
            });
          }
          break;
        case 'administrative_area_level_2':
          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.PROVINCIA) {
                selectedAddressObject[updateGeocodeField.field] = address_component.short_name;
              }
            });
          }
          break;
        case 'administrative_area_level_3':
          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.COMUNE) {
                selectedAddressObject[updateGeocodeField.field] = address_component.long_name;
              }
            });
          }
          break;
        case 'locality':
        case 'postal_town':
          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.COMUNE) {
                if (selectedAddressObject[updateGeocodeField.field] == null || ("" + selectedAddressObject[updateGeocodeField.field]).trim() === "") {
                  selectedAddressObject[updateGeocodeField.field] = address_component.long_name;
                }
              }
            });
          }
          break;
        case 'postal_code':
          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.CAP) {
                selectedAddressObject[updateGeocodeField.field] = address_component.long_name;
              }
            });
          }
          break;
        case 'street_number':
          streetNumberFound = true;

          if (this.props.updateGeocodeFields) {
            this.props.updateGeocodeFields.forEach(updateGeocodeField => {
              if (updateGeocodeField.type === GooglePlacesTypes.CIVICO) {
                selectedAddressObject[updateGeocodeField.field] = address_component.long_name;
              }
            });
          }

          if (this.cleanStreetNumberFromAddress === false) {
            if (selectedAddressObject[this.props.field] === '') {
              selectedAddressObject[this.props.field] = address_component.long_name;
            } else {
              selectedAddressObject[this.props.field] += ' ' + address_component.long_name;
            }
          }
          break;
        case 'route':
          if (selectedAddressObject[this.props.field] == null || selectedAddressObject[this.props.field] === '') {
            selectedAddressObject[this.props.field] = address_component.long_name;
          } else {
            selectedAddressObject[this.props.field] = address_component.long_name + ' ' + selectedAddressObject[this.props.field];
          }
          break;
        default:
          break;
      }
    });

    if (this.props.updateGeocodeFields) {
      this.props.updateGeocodeFields.forEach(updateGeocodeField => {
        if (updateGeocodeField.type === GooglePlacesTypes.LAT) {
          selectedAddressObject[updateGeocodeField.field] = result.geometry.location.lat();
        }
        if (updateGeocodeField.type === GooglePlacesTypes.LNG) {
          selectedAddressObject[updateGeocodeField.field] = result.geometry.location.lng();
        }
      });
    }

    //Se Google non mi ha fornito il numero civico, lo ricavo dalla selezione dell'utente
    if (!streetNumberFound) {
      const streetNumber = address.match(", ([1-9][0-9]{0,3}),");
      if (streetNumber != null) {

        if (this.props.updateGeocodeFields) {
          this.props.updateGeocodeFields.forEach(updateGeocodeField => {
            if (updateGeocodeField.type === GooglePlacesTypes.CIVICO) {
              selectedAddressObject[updateGeocodeField.field] = streetNumber[1];
            }
          });
        }

        if (this.cleanStreetNumberFromAddress === false) {
          selectedAddressObject[this.props.field] += (" " + streetNumber[1]);
        }
      }
    }

    if (this.props.updateGeocodeFields) {
      this.props.updateGeocodeFields.forEach(updateGeocodeField => {
        if (updateGeocodeField.type === GooglePlacesTypes.INDIRIZZO) {
          selectedAddressObject[updateGeocodeField.field] = selectedAddressObject[this.props.field];
        }
      });
    }

    if (this.blankOnPlaceSelected === true) {
      selectedAddressObject[this.props.field] = "";
    }

    this.setState({
      selectedAddressObject: selectedAddressObject
    }, () => {
      if (this.props.updateFields != null) {
        this.props.updateFields(selectedAddressObject);
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <Label>
          {getLocalizedProperty(this.props, "label")} <img alt="GoogleLogo" className="GooglePlacesAutocompleteGoogleLogo" src={imgGoogleLogo} />
        </Label>
        <PlacesAutocomplete
          value={this.state.selectedAddressObject[this.props.field]}
          onChange={this._onChangeInputHandler.bind(this)}
          onSelect={this._onSelectInputHandler.bind(this)}
          debounce={this.props.debounce ? this.props.debounce : 500}
          searchOptions={this.props.searchOptions}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className="GooglePlacesAutocompleteTextField ms-TextField-fieldGroup">
              <TextField
                {...getInputProps({
                  placeholder: intl.get("GooglePlacesAutocomplete.ricercaConGoogle").d("Ricerca con Google..."),
                  className: 'LocationSearchInput',
                })}
                autoComplete="off"
                onBlur={this._onBlurHandler.bind(this)}
                onPaste={this.props.pasteDisabled ? (e) => { e.preventDefault(); } : null}
                disabled={this.props.disabled}
                name={this.props.field}
                required={this.props.required}
                readOnly={this.props.forcedValue ? true : false}
                defaultValue={this.props.forcedValue ? this.props.forcedValue : null}
              />
              <div className="GooglePlacesAutocompleteDropdownContainer">
                {suggestions.map((suggestion, index) => {
                  const className = "GooglePlacesAutocompleteSuggestionItem";
                  return (
                    <div {...getSuggestionItemProps(suggestion, {
                      key: index,
                      className: className
                    })}>
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </React.Fragment>
    );
  }
}
export default GooglePlacesAutocomplete;