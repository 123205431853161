export const SAVE_VIEW_STATE = "SAVE_VIEW_STATE";
export const SHOW_LONG_OPERATION_WAITING_MODAL = "SHOW_LONG_OPERATION_WAITING_MODAL";
export const CLOSE_LONG_OPERATION_WAITING_MODAL = "CLOSE_LONG_OPERATION_WAITING_MODAL";

export const AUTH_CHECK_STATE_START = "AUTH_CHECK_STATE_START";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN = "LOGIN";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const HAS_TO_RESET_PASSWORD = "HAS_TO_RESET_PASSWORD";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
export const UPDATE_VIEW = "UPDATE_VIEW";
export const UPDATE_ALL_VIEW_ON_USER_CHANGED= "UPDATE_ALL_VIEW_ON_USER_CHANGED";

export const UPDATE_SESSION_TIMEOUT = "UPDATE_SESSION_TIMEOUT";
export const SESSION_WARNING_DIALOG = "SESSION_WARNING_DIALOG";
export const SET_SESSION_ENDED = "SET_SESSION_ENDED";

export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export const DISMISS_MODAL = "DISMISS_MODAL";

export const EXPAND_MENU = "EXPAND_MENU";
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const OPEN_MENU = "OPEN_MENU";
export const TOGGLE_MENU_BAR = "TOGGLE_MENU_BAR";
export const SET_OPEN_MENU = "SET_OPEN_MENU";
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const FIRST_OPEN = "FIRST_OPEN";

export const CHANGE_CURRENT_USER = "CHANGE_CURRENT_USER";
export const CURRENT_USER_CHANGED = "CURRENT_USER_CHANGED";
export const CURRENT_USER_CHANGING = "CURRENT_USER_CHANGING";

export const SET_CUR_START_INTERVAL = "SET_CUR_START_INTERVAL";
export const SET_SELECTED_CALENDAR_DATA = "SET_SELECTED_CALENDAR_DATA";
export const SET_IS_FLIPPED = "SET_IS_FLIPPED";

//export const TOGGLE_SEARCH_MODAL = "TOGGLE_SEARCH_MODAL"
export const HIDDEN_SEARCH_MODAL = "HIDDEN_SEARCH_MODAL"
export const SHOW_SEARCH_MODAL = "SHOW_SEARCH_MODAL";

export const LOCALE_CHANGED = "LOCALE_CHANGED"

export const SET_DATATABLE_STATE = "SET_DATATABLE_STATE";
export const SET_PAGE_STATE = "SET_PAGE_STATE";
export const SET_HOME_OPERATIVA_STATE = "SET_HOME_OPERATIVA_STATE";
export const SET_CUSTOM_COMPONENT_STATE = "SET_CUSTOM_COMPONENT_STATE";

export const SHOW_IFRAME_MODAL = "SHOW_IFRAME_MODAL";
export const HIDE_IFRAME_MODAL = "HIDE_IFRAME_MODAL";

export const SHOW_SIDE_PANEL = "SHOW_SIDE_PANEL";
export const HIDE_SIDE_PANEL = "HIDE_SIDE_PANEL";

export const SET_HAS_ERROR_AND_IDCLIENTERROR = "SET_HAS_ERROR_AND_IDCLIENTERROR";
export const RESET_HAS_ERROR_AND_IDCLIENTERROR = "RESET_HAS_ERROR_AND_IDCLIENTERROR";

export const SET_PIVOT_STATE ="SET_PIVOT_STATE";

export const SET_GENERIC_FORM_STATE = "SET_GENERIC_FORM_STATE";