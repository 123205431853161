import React, { Component } from 'react';
import { CommandBar } from 'office-ui-fabric-react';
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/mode/json';
import 'brace/mode/css';
import 'brace/theme/xcode';
import * as actions from '../../store/actions';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import './WagxAceEditor.css';
import { buildMessage, showMessageModal } from '../../functions/utility';
import { MessageSeverityEnum } from '../../enums/MessageSeverityEnum';

class WagxAceEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      structureMaximized: false,
      editorErrors: []
    };
  }

  beautifyJson = () => {
    if (this.state.editorErrors == null || this.state.editorErrors.length === 0) {
      try {
        let o = JSON.parse(this.props.value);
        var nv = JSON.stringify(o, null, 2);
        this.props.onChange(nv);
      } catch (exception) {
        const message = buildMessage(MessageSeverityEnum.ERROR, "WagxAceEditor.error.JsonParseCaption");
        showMessageModal(false, null, message, false);
      }
    } else {
      const message = buildMessage(MessageSeverityEnum.ERROR, this.state.editorErrors[0].text);
      showMessageModal(false, null, message, false);
    }
  }

  minifyJson = () => {
    if (this.state.editorErrors == null || this.state.editorErrors.length === 0) {
      try {
        let o = JSON.parse(this.props.value);
        var nv = JSON.stringify(o);
        this.props.onChange(nv);
      } catch (exception) {
        const message = buildMessage(MessageSeverityEnum.ERROR, "WagxAceEditor.error.JsonParseCaption");
        showMessageModal(false, null, message, false);
      }
    } else {
      const message = buildMessage(MessageSeverityEnum.ERROR, this.state.editorErrors[0].text);
      showMessageModal(false, null, message, false);
    }
  }

  render() {
    const farActions = [];
    const actions = [
      {
        key: 'minimizeMaximize',
        name: this.state.structureMaximized ? intl.get("WagxAceEditor.MinimizeEditor").d("Normal size") : intl.get("WagxAceEditor.MaximizeEditor").d("Fullscreen"),
        iconProps: {
          iconName: this.state.structureMaximized ? "MiniContractMirrored" : "MiniExpandMirrored"
        }, onClick: () => {
          this.setState(prevState => ({
            structureMaximized: !prevState.structureMaximized
          }));
        }
      }
    ];
    if (this.props.mode === 'json') {
      actions.push(
        {
          key: 'formatJson',
          name: intl.get("WagxAceEditor.formatJson").d("Format JSON"),
          iconProps: {
            iconName: 'Code'
          }, onClick: this.beautifyJson
        });
      actions.push(
        {
          key: 'minifyJson',
          name: intl.get("WagxAceEditor.minifyJson").d("Minify JSON"),
          iconProps: {
            iconName: 'Code'
          },
          onClick: this.minifyJson
        });
      if (this.props.updateViewOnRedux === true) {
        actions.push({
          key: 'updateViewRedux',
          name: intl.get("WagxAceEditor.updateViewRedux").d("Update Redux view"),
          iconProps: {
            iconName: 'TestUserSolid'
          },
          onClick: () => {
            console.warn("Updated view with id [" + this.props.objectId + "] in Redux. Save your JSON!\n", this.props.value);
            this.props.updateView(this.props.objectId, this.props.value);
          }
        });
      }
    }
    if (this.state.editorErrors != null && this.state.editorErrors.length > 0) {
      const error = {
        text: this.state.editorErrors[0].text,
        row: ((this.state.editorErrors[0].row) + 1),
        column: this.state.editorErrors[0].column
      };
      farActions.push(
        {
          key: 'error',
          iconProps: {
            iconName: 'Error',
            styles: { color: "red" }
          },
          iconOnly: true,
          text: intl.get("WagxAceEditor.editorError", error).d("Errore: " + error.text + ", Riga: " + error.row + ", Colonna: " + error.column),
          onClick: () => {
            const row = (this.state.editorErrors[0].row) + 1;
            const col = (this.state.editorErrors[0].column);
            this.refs.aceEditor.editor.gotoLine(row, col, true);
            this.refs.aceEditor.editor.focus();
          }
        }
      );
    }
    return (
      <React.Fragment>
        <div className={this.state.structureMaximized ? "editor-fullscreen wagxAceEditor" : "wagxAceEditor"}>
          <CommandBar
            items={actions}
            className="ms-fontColor-themePrimary ms-bgColor-white"
            farItems={farActions}
          />
          <AceEditor ref='aceEditor'
            value={this.props.value}
            mode={this.props.mode}
            theme={this.props.theme}
            name={this.props.name}
            onValidate={this.onValidateHandler}
            width="100%"
            height={(this.state.structureMaximized ? "calc(100% - 50px)" : ((14 * this.props.rows) + "px"))}
            editorProps={{ $blockScrolling: true }}
            tabSize={2}
            showPrintMargin={false}
            onChange={this.props.onChange}
            readOnly={this.props.readOnly}
          />
        </div>
      </React.Fragment>
    );
  }

  onValidateHandler = (errors) => {
    if (errors.length !== this.state.editorErrors.length) {
      this.setState({ editorErrors: [...errors] });
      this.props.onChange(this.props.value, [...errors]);
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateView: (viewId, newView) => dispatch(actions.updateView(viewId, newView))
  }
}
export default withRouter(connect(null, mapDispatchToProps)(WagxAceEditor));
